// This file is used to expose Rails variables to the frontend.
//
// This is the only file with .js.erb extension in the project.
// The reason: reloading .js.erb files is slow as it requires running the Rails runner.
// And with every .js.erb file the compilation time increases.
//
// And no, using Spring is not an option here as it's not compatible with rails-erb-loader:
// https://github.com/usabilityhub/rails-erb-loader/issues/47
//
// It can also be throuth as a way as preventing accidental leaks of sensitive data, as:
// ALL VARIABLES IN THIS FILE ARE VISIBLE IN THE BROWSER'S DEVELOPER TOOLS.




export const sprocketsManifestPath = "sprockets-manifest-4b39c9eb71db9ca8bf30ace96c1b9428.json";

export const railsEnv = "production";
export const release = "960eb32174f0c47394b9b5bd939db3c584fbc12d";

export const constantHelpDefaultAmount = "10";

export const applepayMerchantIdSetting = "merchant.pl.siepomaga";
export const censurableEditorsSetting = ["cause_description", "cause_description_en", "cause_update_message", "cause_result", "cause_result_en"];
export const fingerprintjsDomainSetting = "https://fp.ratujemyzwierzaki.pl";
export const fingerprintjsTokenSetting = "qr6uu0xl3s2DBuEC9Vaj";
export const newsletterTimerUrlSetting = "https://i.countdownmail.com/2eydt4.gif";
export const sentryEnabledEnvironmentsSetting = ["production", "master", "staging"];
export const sentryFrontendDsnSetting = "https://a138507b958b01b3caa817ff6851db78@sentry.siepomaga.pl/7";
