import * as Sentry from "@sentry/browser";
import {
  railsEnv, release, sentryEnabledEnvironmentsSetting, sentryFrontendDsnSetting,
} from "railsVariables";

import { getUserAgentRegex } from "browserslist-useragent-regexp"; // eslint-disable-line import/no-unresolved

const supportedBrowsersRegex = getUserAgentRegex(
  {
    browsers: browserlistConfig,
    ignorePatch: true,
    ignoreMinor: true,
    allowHigherVersions: true,
  },
);

// List of the most popular browsers' request cancellation error messages:
// https://web.archive.org/web/20230712160040/https://request-cancellation-test.vercel.app/
//
// AbortError is included to catch abort errors especially in Firefox on
// Windows. This error is thrown when a request is cancelled.
//
// Sentry errors with the `anulowane` message have also been found, suggesting that `cancelled` is being translated.
const requestCancellationErrorMessages = new Set([
  "TypeError: Failed to fetch",
  "TypeError: NetworkError when attempting to fetch resource.",
  "TypeError: cancelled",
  "TypeError: anulowane",
  "TypeError: Load failed",
  "TypeError: NetworkError: Load failed",
  "AbortError: The operation was aborted.",
]);

if (sentryEnabledEnvironmentsSetting.includes(railsEnv)) {
  Sentry.init({
    dsn: sentryFrontendDsnSetting,
    tunnel: "https://sentry.siepomaga.pl/tunnel", // Cloudflare Worker
    environment: railsEnv,
    release,
    sendDefaultPii: true,

    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications",
      "Can't find variable: _AutofillCallbackHandler",
      "Can't find variable: _pcmBridgeCallbackHandler",
      "Blocked a frame with origin \"https://www.ratujemyzwierzaki.pl\" from accessing a cross-origin frame. Protocols, domains, and ports must match.", // eslint-disable-line max-len
      "Cannot read properties of undefined (reading 'domInteractive')", // error only in Tiktok in-app browser

      // error from FingerprintJS (needs Cloudflare Proxy to be avoided)
      // custom subdomain is not sufficient
      // https://github.com/fingerprintjs/fingerprintjs-pro-react/issues/104#issuecomment-1535632483
      "Failed to load the JS script of the agent",

      // Do not log cancelled fetch requests (ie. closed browser, navigated away)
      // or fetch CORS errors (https://stackoverflow.com/a/60860369/5612001)
      // for requests that are NOT handled by sendRequest (ResponseError).
      ...requestCancellationErrorMessages,
    ],

    allowUrls: [
      new RegExp(`^${window.location.origin}`), // we do not use CDN for assets, so we can just whitelist the origin
    ],

    beforeSend(event) {
      // enhance event with user email
      const enhancedEvent = {
        ...event,
        user: {
          ...event.user,
          email: document.querySelector("meta[name='sentry-user-email']")?.content,
        },
      };

      // Mark all errors from unsupported browsers
      if (!supportedBrowsersRegex.test(navigator.userAgent)) {
        return {
          ...enhancedEvent,
          level: "info", // downgrade to info level
          tags: {
            ...enhancedEvent.tags,
            unsupportedBrowser: "true",
          },
        };
      }

      return enhancedEvent;
    },

    beforeBreadcrumb(breadcrumb) {
      const ignoredUrls = [
        /analytics\.google\.com/,
        /google-analytics\.com/,
        /\/statystyki/,
      ];

      // Do not log fetch/xhr requests to ignoredUrls
      if (breadcrumb.category === "xhr" || breadcrumb.category === "fetch") {
        if (ignoredUrls.some((regex) => regex.test(breadcrumb.data?.url))) {
          return null;
        }
      }

      // Do not log console warnings
      if (breadcrumb.category === "console" && breadcrumb.level === "warning") {
        return null;
      }

      return breadcrumb;
    },

    integrations: [
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ["ratujemyzwierzaki-sentry-app-key"],

        behaviour: "drop-error-if-exclusively-contains-third-party-frames",
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        // reduce bundle size by excluding replay worker from the main bundle
        workerUrl: "/replay_integration_worker.min.js",
        // unmask all text
        // we generally do not need mask any text as admins have access to all data anyway
        // we also send this data to our own Sentry instance, so we do not need to worry about leaking sensitive data
        // but for the good measure we will block all text on all /panel/ pages
        // via [data-sentry-mask] attribute in panel layout
        maskAllText: false,
        mask: [".sentry-mask", "[data-sentry-mask]"],
        // only capture network details if it occurred on our domain
        // so we do not capture any third-party requests, e.g to PayU
        // additionally we do not capture any requests from /panel/ pages
        // or any network details involving authentication or payments
        networkDetailAllowUrls: [window.location.origin],
        networkDetailDenyUrls: [
          /(.*)\/panel\/(.*)/,
          /(.*)\/auth\/(.*)/,
          /(.*)\/user_sessions\/(.*)/,
          /(.*)\/przypomnij-haslo\/(.*)/,
          /(.*)\/platnosc\/(.*)/,
          /(.*)\/karta\/(.*)/,
          /(.*)\/payu\/(.*)/,
          /(.*)\/dotpay\/(.*)/,
          /(.*)\/paypal\/(.*)/,
          /(.*)\/platnosci\/(.*)/,
          /(.*)\/tpay\/(.*)/,
          /(.*)\/blik\/(.*)/,
          /(.*)\/applepay\/(.*)/,
          /(.*)\/googlepay\/(.*)/,
          /(.*)\/credit_card\/(.*)/,
        ],
        beforeAddRecordingEvent: (event) => {
          // Do not capture fetch/xhr requests, unless the response code is an error
          if (
            event.data.tag === "performanceSpan"
            && (event.data.payload.op === "resource.fetch" || event.data.payload.op === "resource.xhr")
            && event.data.payload.data.statusCode < 400
          ) {
            return null;
          }

          return event;
        },
      }),
      Sentry.extraErrorDataIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.contextLinesIntegration(),
      Sentry.sessionTimingIntegration(),
    ],

    // Trace 5% of transactions in production and 100% in other environments
    tracesSampleRate: railsEnv === "production" ? 0.05 : 1.0,
    tracePropagationTargets: ["localhost", /^\//], // default, add API domain if ever created

    // Capture Replay for 100% of sessions with an error
    // do not capture if no error occurred
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}
